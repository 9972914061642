<template>
    <div class="magento-auth">
        <b-row v-if="tokens.length > 0">
            <b-col md="8">
                <wit-select
                    class="text-nowrap"
                    v-model="token"
                    placeholder="Select Account"
                    :options="tokens"
                    id="token-multiselect"
                    @input="emitInput"
                    :disabled="disabled"
                    label="owner"
                    :filterBy="filterBy"
                    preselect-first
                >
                    <div slot="option" slot-scope="option">
                        <span>
                            {{ `${option.owner.name}` }}
                        </span>
                    </div>
                    <div slot="selected-option" slot-scope="option">
                        <span>
                            {{ `${option.owner.name}` }}
                        </span>
                    </div>
                </wit-select>
                <Feedback
                    :state="validateRef('token')"
                    invalid="This field is required"
                    valid="Token is valid"
                ></Feedback>
            </b-col>
            <b-col md="8">
                <span class="magento-reauthorize magento-auth-note">
                    or
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="showModal = true">
                        click here
                    </b-btn>
                    to use a different Account
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length === 0">
            <b-col md="8">
                <span class="magento-auth-note">
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="showModal = true">
                        Click here to authorize
                    </b-btn>
                    <br />
                    You do not seem to have given us access to Magento APIs. You have to authorize first to select an
                    authorized account.
                </span>
            </b-col>
        </b-row>

        <WitModal
            v-model="showModal"
            title="Configure the access"
            variant="primary"
            disableDBlock
            size="lg"
            customClass="step-modal"
        >
            <wit-tabbed-form :formId="formId" :steps="steps" no-header ref="tabbedForm" @input="onFormInput">
                <template v-slot:step-1-form="{step}">
                    <magento-token-form
                        v-model="tokenForm"
                        :step="step"
                        :disabled="disabled"
                        :valid.sync="formValid"
                    ></magento-token-form>
                </template>
                <template v-slot:step-1-footer="{step}">
                    <b-row>
                        <b-col>
                            <Feedback
                                :state="step.checked ? !step.invalid : null"
                                invalid="You need to finish this step before accessing the next one"
                                valid="This step is complete"
                            ></Feedback>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <Feedback
                                v-if="tokenError"
                                :state="tokenError.state"
                                :invalid="tokenError.message"
                            ></Feedback>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <magento-token-button
                                :form-id="formId"
                                :step="step"
                                :token-form="tokenForm"
                                :token-error.sync="tokenError"
                                @authorizedSuccess="onAuthorizedSuccess"
                                @authorizedError="onAuthorizedError"
                            ></magento-token-button>
                        </b-col>
                    </b-row>
                </template>
            </wit-tabbed-form>
        </WitModal>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        Feedback,
        MagentoTokenButton: () => import('@/components/Tokens/Buttons/MagentoTokenButton.vue'),
        MagentoTokenForm: () => import('@/components/Tokens/Forms/MagentoTokenForm.vue'),
        WitModal: () => import('@/components/Modals/WitModal.vue'),
        WitTabbedForm: () => import('@/components/WitTabbedForm.vue'),
    },
    props: {
        value: {
            type: String | null,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: String | null,
        },
    },
    mixins: [formMixin, validationMixin],
    validations: {
        token: {required},
        tokenForm: {
            valid: function() {
                return this.formValid
            },
        },
    },
    watch: {
        value() {
            this.token = this.tokens.find(el => el.id === this.value)
        },
        async projectId() {
            await this.$store.dispatch('token/fetchTokens', this.projectId)
            this.token = this.tokens.find(el => el.id === this.value)
        },
    },
    async created() {
        await this.$store.dispatch('token/fetchTokens', this.projectId)
        this.token = this.tokens.find(el => el.id === this.value)
    },
    data() {
        return {
            token: null,
            formValid: false,
            showModal: false,
            cannotContinue: true,
            formId: 'magento-token-modal',
            tokenForm: {},
            tokenError: {
                state: true,
                message: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/magentoTokens',
        }),
        steps() {
            return [
                {
                    name: 'Authorization',
                    invalid: this.$v.tokenForm.$invalid,
                },
            ]
        },
    },
    methods: {
        emitInput() {
            if (this.token) {
                this.$emit('input', this.token.id)
            } else {
                this.$emit('input', null)
            }
        },
        async onAuthorizedSuccess() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)
            await this.$store.dispatch('token/fetchTokens', this.projectId)
            this.showModal = false
            this.$store.commit('loading/PROCESSED')
        },
        onAuthorizedError() {
            this.$errorHandler.report(exception, 'Could not upsert Magento token')
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
        filterBy(option, label, search) {
            return `${option.owner.name}`.toLowerCase().indexOf(search.toLowerCase()) > -1
        },
    },
}
</script>

<style lang="scss">
.magento-reauthorize {
    opacity: 0.8;
}
.magento-auth {
    .magento-auth-note {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.7rem;
        img {
            width: 150px;
        }

        img.pointer-disabled {
            filter: grayscale(70%);
        }
    }
}
</style>
